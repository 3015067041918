<template>
    <router-link to="/newyear">
        <div class="ny" :class='$mq'>
            <div class="nybg" :class='$mq'>
                <div class="nyblock" :class='$mq'>
                    <div class="nydoll" :class='$mq'>
                        <KImage :src="require('@/assets/newyear/push/doll-ny.png')"  alt="Art doll" width="100%"/>
                    </div>

                    <div class="nytext" :class='$mq'>
                        <h2>Cоздай невероятную <br/> авторскую куклу</h2>                    
                        <KRoundedButton class="nybutton" text-color="#fff" color="#dc4444">
                            Создать куклу
                        </KRoundedButton>
                    </div>

                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import KImage from "../../Widgets/KImage";
    import KRoundedButton from "../../Widgets/KRoundedButton";

    export default {
        components: {
            KRoundedButton,
            KImage,
        }
    }
</script>

<style scoped>
    .ny {
        width: 100%;
        position: fixed;
        bottom: 0;
        color: white;
        z-index: 9999;
    }

        .nybg {
            background-color: #320830;
            position: relative;
            height: 90px;
             background-image: url('~@/assets/newyear/push/light-ny.png');
            background-repeat:  no-repeat;
            background-size: 1432px 90px;
            background-position: center;
        }
            .nybg.halftablet, .nybg.mobile {
                height: 70px;
            }

            .nyblock {        
                max-width: 950px;
                margin: 0 auto;
                height: 100%;
                position: relative;
                background-image: url('~@/assets/newyear/push/top-ny.png');
                background-repeat: no-repeat;
                background-size: 305px;
                background-position: right top;
                display: flex;
                align-items: center;
                justify-content: center;
            }
                .nyblock.tablet {
                    background-position: calc(100% + 100px) 0;
                }
                .nyblock.halftablet,
                .nyblock.mobile {
                    background-position: calc(100% + 160px) 0;
                }

                .nydoll {
                    width: 164px;
                    height: 100%;;
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                }
                    .nydoll.halftablet{
                        left: -2.5em;
                        width: 130px;
                        bottom: 40px;
                    }
                    .nydoll.mobile {
                        left: -2em;
                        width: 130px;
                        bottom: 40px;
                    }
                .nytext {
                    display: flex;
                    align-items: center;
                }
                    .nytext.tablet {
                        margin-left: 4em;
                    }
                    .nytext.halftablet {
                        margin-left: 5em;
                    }
                    .nytext h2 {
                        font-size: 1.5em;
                        text-transform: uppercase;
                        line-height: 1.2;
                    }
                        .nytext.tablet h2 {
                            font-size: 1.3em;
                        }
                        .nytext.halftablet h2 {
                            font-size: 0.9em;
                        }
                        .nytext.mobile h2 {
                            display:none;
                        }

                    .nybutton {
                        font-size: 1em;
                        padding: 1em 1.5em!important;
                        margin-left: 1.5em;
                    }
                        .nytext.tablet .nybutton,
                        .nytext.halftablet .nybutton {
                            font-size: 0.9em;
                            padding: 1em 1em!important;
                            margin-left: 1em;
                        }
                        .nytext.mobile .nybutton {
                            font-size: 0.9em;
                            padding: 1em 1em!important;
                            margin-left: 1em;
                        }
    
</style>